/* src/styles/App.css */
.learn-container {
  font-family: "Manjari", sans-serif;
  padding: 20px;
  margin-top: 130px;

}

.rss-widget-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

/* Reduced size subscribe button */
.libutton {
  display: flex;
  justify-content: center;
  padding: 10px 20px;
  background-color: #849f30;
  color: white;
  font-size: 16px;
  border-radius: 16px;
  text-decoration: none;
  width: auto;
  margin-top: 20px;
  transition: background-color 0.3s;
}

.libutton:hover {
  background-color: #849f30;
}
/* Search Bar Styling */
.search-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px auto;
  width: 100%;
  max-width: 600px;
  gap: 8px;
}

.search-input {
  width: 80%;
  padding: 10px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.search-button {
  padding: 10px 16px;
  font-size: 16px;
  color: #fff;
  background-color: #849f30;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.search-button:hover {
  background-color: #849f30;
}

/* Ensure proper spacing */
.rss-widget-container {
  margin-top: 16px;
}

/* Small button size */
.libutton.small {
  padding: 20px 15px;
  font-size: 20px;
  margin-bottom: 30px;

}
