/* Hero Section */
.hero {
  display: flex;
  text-align: left;
  padding: 200px 120px;
  position: relative;
  overflow: hidden; /* Ensures content does not overflow */
  color: rgb(3, 0, 0);
  background: linear-gradient(to right, rgba(0, 0, 0, 1.7) 15%, rgba(0, 0, 0, 0) 100%), url('./home.webp') no-repeat center center/cover;
  image-rendering: smooth;
  margin-bottom: 0;

}
.hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.02); /* Adjust the opacity as needed */
  pointer-events: none; /* Ensures the overlay doesn't interfere with other elements */
}
.hero-content {
  position: relative;
  z-index: 1; /* Ensures content is above the overlay */
}
.hero-text {
    max-width: 53%;

}
.hero h1 {
    font-family: "League Spartan", sans-serif;
    font-size: 3.5rem;
    margin-bottom: 40px;
    color: rgb(169, 190, 93);

}
.hero p {
    font-family: "Manjari", sans-serif;
    font-size: 1.2rem;
    margin-bottom: 40px;
    line-height: 1.8;
    color: white; /* Adjust text color to ensure visibility */
}
.hero-buttons {
    display: flex;
    gap: 30px;
}
.primary-button,
.secondary-button {
    padding: 15px 30px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    font-family: "Manjari", sans-serif;
    font-weight: 600;
}
.primary-button {
    background-color: rgb(150, 178, 61);
    color: white;
}
.secondary-button {
    background-color: transparent;
    color: rgb(150, 178, 61);
    border: 2px solid rgb(150, 178, 61);
}




/* Action Cards */
.action-cards {
  display: grid; /* Use grid layout */
  grid-template-columns: repeat(2, 1fr); /* Create two equal columns */
  gap: 30px; /* Add space between the cards */
  margin-top: 0%;
}
.action-card-black{

  background-color: black;
  height: 200px;
  border-radius: 3px;
  padding: 20px;
  text-align: center;
  
  display: flex;
  flex-direction: column;
  color: rgb(255, 255, 255);
  justify-content: space-between;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2); /* Stronger shadow for cards */


}
.action-card-white{
  background-color: white;
  height: 200px;
  border-radius: 3px;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
box-shadow: 0 30px 40px rgba(0, 0, 0, 0.2); /* Stronger shadow for cards */
}

.action-card h3 {
  font-family: "League Spartan", sans-serif;
  font-size: 1.5rem;
  color: rgb(51, 51, 51);
  margin-bottom: 15px;
}

.action-card p {
  font-family: "Manjari", sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  color: rgb(102, 102, 102);
}
.action-card-white-button {
  padding: 10px 20px;
  background-color: rgb(150, 178, 61);
  color: white;
  padding: 15px 210px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  font-family: "Manjari", sans-serif;
  font-weight: 600;
}
.action-card .button:hover {
  background-color: rgb(120, 150, 50);
}

.action-card-black-button {
  padding: 10px 20px;
  background-color: rgb(150, 178, 61);
  color: white;
  padding: 15px 210px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  font-family: "Manjari", sans-serif;
  font-weight: 600;
}
.action-card .button:hover {
  background-color: rgb(120, 150, 50);
}








/* About Us Section */
.about {
  padding: 60px 80px;
  background-color: white; /* Soft green background */
  color: rgb(51, 51, 51);
  text-align: center;
}

.about h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  font-family: 'League Spartan', sans-serif;
  font-size: 3rem;
  margin-bottom: 40px;
  color: #2e2e2e; /* Deep color for headers */
  letter-spacing: 2px;
  position: relative;
  z-index: 1;  }
  .about h2::after {
      content: "";
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
      width: 100px;
      height: 4px;
      background-color: #a3c940; /* Accent line under the heading */
      border-radius: 2px;
    }
.about p {
  font-size: 1.2rem;
  max-width: 800px;
  margin: 0 auto;
  font-family: 'Manjari', sans-serif;

  line-height: 1.8;
  color: rgb(51, 51, 51);
  font-weight: 300;
}
.learn-more {
  padding: 10px 20px;
  color: rgb(150, 178, 61);
  background-color: white;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: "Manjari", sans-serif;
  font-weight: 600;
  display: inline-flex; /* Use inline-flex instead of flex to prevent it from stretching */
  align-items: center;
  justify-content: center;
  gap: 8px; /* Space between text and arrow */
  margin: 20px auto; /* Center the button horizontally and add spacing above/below */
}

.learn-more .arrow {
  font-size: 1.5rem; /* Adjust the size of the arrow */
  margin-left: 5px; /* Space between text and arrow */
  transition: transform 0.3s ease;
}

.learn-more:hover .arrow {
  transform: translateX(5px); /* Slight movement when hovered */
}




/* Services Section */
.services {
  padding: 80px 10%;
  background: radial-gradient(
    circle closest-side,
    #c1d68a 12%, 
    white 100%
  );
}

.services h2 {
  font-size: 2.5rem;
  margin-bottom: 40px;
  font-family: 'League Spartan', sans-serif;
  color: #2e2e2e;
  text-align: center;
}

.services h2::after {
  content: "";
  display: block;
  margin: 10px auto 0;
  width: 100px;
  height: 4px;
  background-color: #a3c940;
  border-radius: 2px;
}

.service-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
  margin-top: 60px;
}

.service-card {
  background-color: white;
  padding: 20px;
  border-radius: 0px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}

.service-card h3 {
  font-size: 1.5rem;
  margin-bottom: 15px;
  font-family: 'Urbanist', sans-serif;
  color: #333;
  text-align: center;
}

.service-card p {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: auto;
  text-align: center;
  color: #555;
}

.service-card a {
  display: inline-block;
  margin-top: 15px;
  font-size: 1rem;
  color: #a3c940;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.service-card a:hover {
  color: #849f30;
}
.service-button{
  padding: 6%;
}


/* Industries Section */
.industries-section {
  padding: 40px 20px;
  background-color: transparent;
  border-radius: 8px;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

.industries-section h2 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
  font-weight: bold;
}

.tabs {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 20px;
}

.tab {
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: 500;
  color: #555;
  background-color: #e0e0e0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.tab:hover {
  background-color: #d4d4d4;
  color: #000;
}

.tab.active {
  background-color: #a3c940;
  color: #fff;
  box-shadow: 0 4px 8px rgba(0, 123, 255, 0.2);
}

.tab-content {
  font-size: 1rem;
  color: #666;
  line-height: 1.6;
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.tab-content p {
  margin: 0;
}

/* Free Consultation Card */
.free-consultation-card {
  background: linear-gradient(145deg, #292929, #1f1f1f);
  border-radius: 15px;
  padding: 20px;
  margin-top: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.free-consultation-card h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #f5f2f2;
}

.free-consultation-card p {
  font-size: 1.1em;
  color: #e9e6e6;
  margin-bottom: 15px;
}

.schedule-consultation-btn {
  background-color: #a3c940;
  color: white;
  font-size: 1.2em;
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.schedule-consultation-btn:hover {
  background-color: #a3c940;
}





.trending-topics {
  position: relative;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: hidden; /* Ensure shapes don't overflow the section */

  /* Add gradient background */
  background: linear-gradient(135deg, #a3c940, #ffffff);
  background-size: 200% 200%;
  animation: gradientMotion 15s ease infinite;
}

.trending-topics .shape {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.05);
  animation: shapeMotion 10s ease-in-out infinite;
  pointer-events: none;
  opacity: 0.4;
}

.trending-topics .shape-one {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  left: -100px;
  top: -50px;
}

.trending-topics .shape-two {
  width: 400px;
  height: 400px;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@keyframes gradientMotion {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

@keyframes shapeMotion {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}


.trending-topics-header {
  display: flex;
  justify-content: space-between;

}

.trending-topics h2 {
  margin: 0; /* Removes unnecessary margin */
  font-size: 1.5rem;
  color: #333;
  text-align: left;


}

.more-topics {
  color: #333;
  text-decoration: none;
  font-weight: bold;
  font-size: 1rem;
  text-align: right;

}

.topic-container {
  display: flex;
  justify-content: space-between;
  gap: 15px; /* Space between cards */
}

.topic {
  flex: 1;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  color: #333;
}

/* Assigning Random Muted Colors to Cards */
.topic:nth-child(1) {
  background-color: #FCE8D8; /* Muted orange for Generative AI */
}

.topic:nth-child(2) {
  background-color: #D8F1E9; /* Muted green for Environmental, Social & Governance */
}

.topic:nth-child(3) {
  background-color: #E8E8FC; /* Muted blue for AdTech */
}

.topic h3 {
  margin: 0;
  font-size: 1.2rem;
  font-weight: bold;
}

/* Stay Updated Section */
.stay-updated {
  margin-top: 2rem;
}

.stay-updated h3 {
  font-size: 1.5rem;
  color: #333; /* Darker text for readability */
  margin-bottom: 0.5rem;
  text-align: left;

}

.stay-updated p {
  font-size: 1rem;
  color: #666; /* Muted text color */
  margin-bottom: 1rem;
}

.email-subscription {
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: center; /* Center-align input and button */
  gap: 1rem; /* Spacing between text and input */
}

.email-subscription input {
  width: 80%; /* Increased width for better visibility */
  max-width: 500px; /* Maximum width for larger screens */
  padding: 0.75rem;
  border: 1px solid #ddd; /* Subtle border */
  border-radius: 4px;
  font-size: 1rem;
  outline: none;
  transition: border-color 0.3s ease;
}

.email-subscription input:focus {
  border-color: #88c57f; /* Highlight color on focus */
}

.email-subscription button {
  width: auto; /* Adapts to content size */
  padding: 0.75rem 1.5rem;
  background-color: #88c57f; /* Sage green button */
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.email-subscription button:hover {
  background-color: #76b06b; /* Slightly darker green on hover */
}

.email-subscription button:active {
  background-color: #65965a; /* Even darker green when clicked */
}






/* Testimonials Section */
/* Testimonials Section */
.testimonials-section {
  background: linear-gradient(145deg, #1a1919, #141414);
  padding: 80px 100px;
}
.testimonial-card {
  flex: 0 0 300px; /* Fixed width for each card */
  background: linear-gradient(145deg, #292929, #1f1f1f);
  border: 1px solid #333;
  border-radius: 15px;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.6), 0 -2px 10px rgba(255, 255, 255, 0.05);
  padding: 20px;
  text-align: left;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
}
.testimonials-section h2 {
  font-size: 2.5rem;
  margin-bottom: 30px;
  color: #ffffff;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.testimonials-wrapper {
  display: flex;
  gap: 20px;
  overflow-x: auto;
  scroll-behavior: smooth;
  padding: 10px 0;
}

.testimonials-wrapper::-webkit-scrollbar {
  display: none; /* Hide scrollbar for a clean design */
}

.testimonial-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.8), 0 -5px 15px rgba(255, 255, 255, 0.1);
}

.testimonial-date {
  font-size: 0.9rem;
  color: #999;
  margin-bottom: 10px;
}

.testimonial-card h3 {
  font-size: 1.3rem;
  color: #ffffff;
  margin-bottom: 10px;
}

.testimonial-category {
  font-size: 1rem;
  color: #a3c940;
  margin-bottom: 10px;
}

.testimonial-author {
  font-size: 0.9rem;
  color: #ccc;
}

/* Responsive Design */
@media (max-width: 768px) {
  .testimonials-section h2 {
    font-size: 2rem;
  }

  .testimonial-card {
    flex: 0 0 90%; /* Make cards larger on smaller screens */
    margin: 0 auto;
  }
}








/* Existing CSS Above */

/* Responsive Design for Smaller Screens */
@media (max-width: 1200px) {
  /* Hero Section Adjustments */
  .hero {
    flex-direction: column;
    text-align: center;
    padding: 40px 60px;
  }

  .hero-content {
    flex-direction: column;
  }

  .hero-text {
    max-width: 90%;
  }

  .hero h1 {
    font-size: 3rem;
  }

  .hero p {
    font-size: 1.1rem;
  }

  .hero-buttons {
    flex-direction: column;
    gap: 15px;
  }


}

@media (max-width: 768px) {
  /* General Text and Padding Adjustments */
  body {
    font-size: 14px;
  }

  .hero {
    padding: 30px 20px;
  }

  .hero h1 {
    font-size: 2.5rem;
  }

  .hero p {
    font-size: 1rem;
  }

  .primary-button,
  .secondary-button {
    padding: 10px 20px;
    font-size: 0.9rem;
  }

  /* Services Section */
  .services {
    padding: 50px 5%;
  }

  .service-cards {
    grid-template-columns: 1fr; /* Single column layout */
    gap: 20px;
  }

  /* Action Cards */
  .action-cards {
    grid-template-columns: 1fr; /* Single column for smaller screens */
  }

  .action-card-black,
  .action-card-white {
    height: auto;
    padding: 15px;
  }
}

@media (max-width: 480px) {
  /* Extra Small Devices */
  .hero h1 {
    font-size: 2rem;
    margin-bottom: 20px;
  }

  .hero p {
    font-size: 0.9rem;
    margin-bottom: 20px;
    line-height: 1.5;
  }

  .hero-buttons {
    gap: 10px;
  }

  .services h2 {
    font-size: 2rem;
  }

  .service-cards div {
    padding: 20px;
  }



 
}
