/* General Styling */
.privacy-policy-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

h1, h2, h3 {
  color: #333;
}

h1 {
  font-size: 2rem;
  margin-bottom: 20px;
}

h2 {
  font-size: 1.5rem;
  margin-top: 20px;
}

h3 {
  font-size: 1.2rem;
  margin-top: 15px;
}

p, ul, address {
  color: #555;
}

a {
  color: #007BFF;
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  color: #0056b3;
}

/* List Styling */
ul {
  padding-left: 20px;
  margin: 10px 0;
}

ul li {
  margin-bottom: 10px;
}

/* Address Styling */
address {
  font-style: normal;
  line-height: 1.8;
}

/* Responsive Design */
@media (max-width: 768px) {
  .privacy-policy-container {
    padding: 15px;
  }

  h1 {
    font-size: 1.8rem;
  }

  h2 {
    font-size: 1.3rem;
  }

  h3 {
    font-size: 1.1rem;
  }

  p, ul {
    font-size: 0.9rem;
  }

  address {
    font-size: 0.9rem;
  }
}
