/* Styling for the contact-options-section */
.contact-options-section {
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    align-items: center;
    padding: 170px;
    background: rgb(34, 34, 34);    
    gap: 20px;
    font-family: 'Manjari', sans-serif;
    position: relative; /* Ensure relative positioning for z-index layering */
    color: #ffffff;
}



/* Styling for the Talk to Us heading */
.contact-heading {
    color: #fdfbfb;
    text-align: center; /* Center the text */
    margin-bottom: 30px; /* Adds space between the heading and the contact options */
}

/* Styling for contact cards container */
.contact-cards {
    display: flex;
    justify-content: space-between; /* Space out the cards */
    width: 100%; /* Ensure it takes the full width */
    gap: 20px; /* Space between the cards */
}

/* Styling for individual contact options */
.contact-option {
    width: 45%; /* Adjusted to allow space between cards */
    text-align: center;
    background: #ffffff;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact-option h2 {
    font-size: 1.8rem;
    color: rgb(150, 178, 61);
    margin-bottom: 10px;
}

.contact-option p {
    font-size: 1.6rem;
    color: #fcf9f9;
    margin-bottom: 15px;
}

/* Styling for links */
.contact-link {
    color: #fff;
    background-color: rgb(150, 178, 61);
    text-decoration: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-weight: bold;
    transition: background-color 0.3s, transform 0.3s;
}

.contact-link:hover {
    background-color: #8fa349;
    transform: scale(1.05);
}

.contact-link:active {
    transform: scale(0.95);
}

/* Responsive design for mobile */
@media (max-width: 768px) {
    .contact-options-section {
        flex-direction: column;
        gap: 15px;
    }

    .contact-cards {
        flex-direction: column; /* Stack cards vertically on smaller screens */
        width: 100%;
    }

    .contact-option {
        width: 100%; /* Full width for mobile */
    }
}

/* General styling for other sections */
.contact-section {
    margin-top: 40px;

    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Manjari', sans-serif;
    padding: 80px;
    background: linear-gradient(
      to top,
      rgb(224, 242, 201) 0%, /* Light green sage accent */
      rgb(224, 242, 201) 50%, /* Keeps the green in the top part */
      white 100% /* Smooth transition to white */
    );    gap: 40px;
}

.contact-left,
.contact-right {
    width: 100%;
    max-width: 500px;
    text-align: center;
}

.contact-left {
    flex: 1;
}

.contact-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: rgb(150, 178, 61);
    margin-bottom: 15px;
}

.contact-description {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 10px;
}
.contact-desc {
    font-size: 1.2rem;
    color: rgb(150, 178, 61);
    margin-bottom: 10px;
}
.contact-email a {
    color: rgb(150, 178, 61);
    font-weight: bold;
    text-decoration: none;
}

.contact-email a:hover {
    text-decoration: underline;
}
.contact-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.contact-form-title {
    font-size: 1.5rem;
    color: rgb(150, 178, 61);
    margin-bottom: 20px;
}

.contact-form input,
.contact-form textarea {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 2px solid transparent;
    border-radius: 5px;
    outline: none;
    transition: border-color 0.3s;
}

.contact-form input:focus,
.contact-form textarea:focus {
    border-color: rgb(150, 178, 61);
}

.contact-form textarea {
    resize: none;
    height: 100px;
}

.contact-submit {
    width: 105%; /* Match the width of input fields */
    box-sizing: border-box; /* Ensure padding doesn't affect width */
    background-color: black;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px;
    font-size: 1rem;
    cursor: pointer;
    transition: transform 0.3s, background-color 0.3s;
    margin: 0; /* Remove any default margins */
}


.contact-submit:hover {
    background-color: #8fa349;
    transform: scale(1.05);
}

.contact-submit:active {
    transform: scale(0.95);
}

/* Responsive design for larger screens */
@media (min-width: 768px) {
    .contact-section {
        flex-direction: row;
        justify-content: space-between;
    }

    .contact-left,
    .contact-right {
        text-align: left;
    }
}
.connect-section {
    display: flex; /* Enable Flexbox */
    justify-content: space-between; /* Space between items */
    align-items: center; /* Vertically align items */
    padding: 20px; /* Add some spacing */
    background: linear-gradient(
        to bottom,
        rgb(224, 242, 201) 0%, /* Light green sage accent */
        rgb(224, 242, 201) 50%, /* Keeps the green in the top part */
        white 100% /* Smooth transition to white */
    ); 
}

.connect-section h1 {
    font-size: 2.5rem;
    margin: 70px; /* Remove extra margin */
    color: rgb(150, 178, 61);
}

.social-media-icons {
    display: flex;
    gap: 127px;
    margin-right: 50px; /* Remove extra margin */

}

.social-media-icons a {
    color: rgb(150, 178, 61); /* Default color */
    text-decoration: none;
    transition: color 0.3s ease;
}

.social-media-icons a:hover {
    color: #50c45a; /* Change color on hover (you can set your preferred hover color) */
}
/* Responsive design for smaller screens - below 768px */
@media (max-width: 768px) {
    .contact-options-section {
        padding: 50px; /* Reduce padding */
        gap: 15px; /* Reduce gap between elements */
    }

    .contact-cards {
        flex-direction: column; /* Stack cards vertically */
        gap: 10px; /* Smaller gap between cards */
    }

    .contact-option {
        width: 100%; /* Full width */
        padding: 15px; /* Adjust padding for better spacing */
    }

    .contact-option h2 {
        font-size: 1.5rem; /* Reduce font size */
    }

    .contact-option p {
        font-size: 1.2rem; /* Reduce paragraph font size */
    }

    .contact-heading h1 {
        font-size: 2rem; /* Adjust heading size */
    }

    .contact-section {
        padding: 40px; /* Reduce padding */
        gap: 20px; /* Reduce gap */
    }

    .contact-title {
        font-size: 2rem; /* Smaller title */
    }

    .contact-description {
        font-size: 1rem; /* Adjust description font size */
        text-align: left;
    }

    .connect-section {
        flex-direction: column; /* Stack items vertically */
        padding: 20px; /* Adjust padding */
        gap: 20px; /* Add spacing between sections */
    }

    .connect-section h1 {
        font-size: 2rem; /* Smaller font size */
        margin: 20px 0; /* Adjust margin */
    }

    .social-media-icons {
        gap: 20px; /* Reduce gap between icons */
        margin-right: 0; /* Remove right margin */
    }
}

/* Responsive design for extra small screens - below 480px */
@media (max-width: 480px) {
    .contact-options-section {
        padding: 30px; /* Further reduce padding */
    }

    .contact-heading h1 {
        font-size: 1.8rem; /* Smaller heading size */
    }

    .contact-option {
        padding: 10px; /* Compact padding */
    }

    .contact-option h2 {
        font-size: 1.3rem; /* Smaller heading in cards */
    }

    .contact-option p {
        font-size: 1rem; /* Smaller paragraph font size */
    }

    .contact-link {
        padding: 8px 15px; /* Smaller button padding */
        font-size: 0.9rem; /* Adjust button text size */
    }

    .contact-section {
        padding: 20px; /* Minimal padding */
    }

    .contact-title {
        font-size: 1.8rem; /* Compact title */
    }

    .contact-description {
        font-size: 0.9rem; /* Smaller font size */
    }

    .contact-form input,
    .contact-form textarea {
        padding: 8px; /* Compact input padding */
        font-size: 0.9rem; /* Adjust input font size */
    }

    .contact-submit {
        font-size: 0.9rem; /* Smaller button text */
        padding: 8px; /* Compact button padding */
    }

    .social-media-icons {
        gap: 3px; /* Reduce gap between icons */
    }

    .social-media-icons a {
        font-size: 1.1rem; /* Smaller icon size */
    }
}
