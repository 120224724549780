/* Styling for the footer */
.footer {
  background-color: black; /* Black background */
  color: white; /* White text */
  padding: 30px 0;
  font-family: Arial, sans-serif;
}

/* Footer content section */
.footer-content {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  margin-bottom: 20px;
}

/* Individual footer sections */
.footer-section {
  width: 30%;
}

.footer-section h3 {
  font-size: 1.5rem;
  margin-bottom: 15px;
}

.footer-section p,
.footer-section ul {
  font-size: 1rem;
  line-height: 1.6;
}

/* Styling for the links */
.footer-section a {
  color: white;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-section a:hover {
  color: #cdecdc; /* Light green color when hovered */
}

.footer-section ul {
  list-style-type: none;
  padding-left: 0;
}

.footer-section ul li {
  margin-bottom: 10px;
}

/* Social icons */
.social-icons {
  margin-top: 10px;
}

.social-icons a {
  color: white;
  margin-right: 15px;
  font-size: 1.5rem;
  transition: color 0.3s ease;
}

.social-icons a:hover {
  color: #cdecdc; /* Light green color when hovered */
}

/* Footer bottom section */
.footer-bottom {
  text-align: center;
  padding: 10px 20px;
  border-top: 1px solid #444;
}

.footer-bottom p {
  margin-bottom: 10px;
}

.footer-bottom a {
  color: white;
  text-decoration: none;
  font-weight: bold;
}

.footer-bottom a:hover {
  color: #cdecdc;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column; /* Stack footer sections vertically */
    align-items: center; /* Center align sections */
    padding: 0 10px; /* Adjust padding for small screens */
  }

  .footer-section {
    width: 100%; /* Full width for each section */
    text-align: center; /* Center align text */
    margin-bottom: 20px; /* Add spacing between sections */
  }

  .footer-section h3 {
    font-size: 1.2rem; /* Smaller heading size for smaller screens */
  }

  .footer-section p,
  .footer-section ul {
    font-size: 0.9rem; /* Slightly smaller font size for content */
  }

  .social-icons a {
    font-size: 1.2rem; /* Adjust icon size */
    margin-right: 10px; /* Reduce margin for icons */
  }

  .footer-bottom {
    padding: 10px 10px; /* Reduce padding */
  }

  .footer-bottom p {
    font-size: 0.9rem; /* Adjust text size */
  }
}
