/* Overall container for About Us page */
.about-us-container {
  padding: 50px;
  font-family: 'Manjari', sans-serif;
  background: linear-gradient(to bottom, white 0%, #e0f2c9 100%);
  color: #050700;
  line-height: 1.8;
  max-width: 1400px;
  margin-top: 450px;

  margin: 20px auto;
  border-radius: 10px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
}

/* Section Titles */
.section-title {

  font-size: 2.5rem;
  color: #050700;
  text-align: center;
  margin-bottom: 40px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-family: 'Manjari', sans-serif;
  border-bottom: 2px solid #a8c76c;
  padding-bottom: 10px;
}

/* Our Story Section */
.our-story-section {
  margin-bottom: 60px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  align-items: center;
}

.our-story-section h2 {
  font-size: 2rem;
  color: #050700;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: left;
}

.story-text {
  font-size: 1rem;
  color: #050700;
  line-height: 1.8;
  text-align: left;
}

/* Expertise Section */
.our-expertise {
  margin-bottom: 60px;
}

.expertise-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 30px;
}

.expertise-item {
  background: #e0f2c9;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  color: #050700;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.expertise-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}

/* Why Us Section */
.why-us-section {
  margin-bottom: 60px;
  background: #e0f2c9;
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.why-us-content h3 {
  font-size: 2rem;
  color: #050700;
  font-weight: 700;
  margin-bottom: 20px;
}

.why-us-content p {
  font-size: 1rem;
  color: #050700;
  margin-bottom: 20px;
}

/* Contact Us Button */
.contact-us-button {
  display: inline-block;
  padding: 12px 30px;
  background: #9ab23d;
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  text-decoration: none;
  transition: background 0.3s ease, box-shadow 0.3s ease;
}

.contact-us-button:hover {
  background: #7a992b;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

/* Initiatives Section */
.our-initiatives-section {
  margin-bottom: 60px;
}

.initiative-card {
  margin-bottom: 30px;
  padding: 30px;
  background: #ffffff;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.initiative-card h2 {
  font-size: 1.8rem;
  color: #050700;
  margin-bottom: 15px;
}

.initiative-card p {
  font-size: 1rem;
  color: #050700;
  margin-bottom: 15px;
}

.initiative-link {
  display: inline-block;
  color: #9ab23d;
  font-size: 1rem;
  font-weight: 600;
  text-decoration: none;
  border-bottom: 2px solid #9ab23d;
  transition: color 0.3s ease, border-color 0.3s ease;
}

.initiative-link:hover {
  color: #7a992b;
  border-color: #7a992b;
}

/* Media Queries for responsive layout */
@media screen and (max-width: 768px) {
  .our-story-section {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .expertise-list {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .contact-us-button {
    width: 100%;
    padding: 12px 20px;
  }

  .initiative-card {
    padding: 20px;
  }

  .section-title {
    font-size: 2rem;
  }
}
