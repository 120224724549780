/* ContactForm.css */
.contact-form-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  .success-message{
    color: #333;
    .success-overlay {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 128, 0, 0.1);
        padding: 20px;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      }
      
      .success-message {
        color: #28a745;
        font-size: 18px;
        margin-bottom: 10px;
      }
      
      .success-icon {
        font-size: 40px;
        color: #28a745;
      }
      
      .contact-form-container {
        position: relative; /* Allow positioning of success overlay */
        max-width: 600px;
        margin: 0 auto;
        padding: 20px;
        background-color: #f9f9f9;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      }
      
  }
  .contact-form-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .contact-form input,
  .contact-form textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    outline: none;
    transition: border-color 0.3s ease;
  }
  
  .contact-form input:focus,
  .contact-form textarea:focus {
    border-color: #849f30;
  }
  
  .contact-form textarea {
    resize: vertical;
    min-height: 120px;
  }
  
  .primary-button {
    padding: 12px 20px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background-color: #849f30;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .primary-button:hover {
    background-color: #2a3114;
  }
  
  .success-message {
    margin-top: 15px;
    color: #28a745;
    font-size: 16px;
    text-align: center;
  }
  
  /* Responsive Design */
  @media (max-width: 480px) {
    .contact-form-container {
      padding: 15px;
    }
  
    .contact-form-title {
      font-size: 20px;
    }
  
    .primary-button {
      font-size: 14px;
      padding: 10px 15px;
    }
  }
  