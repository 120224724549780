/* ServicePage.css */

/* General Styles */
body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    color: #333;
    line-height: 1.6;
}
/* Style for the buttons */
.service-card-buttons {
    margin-top: 15px;
    display: flex;
    gap: 10px;
  }
  
  .talk-button, .quote-button {
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .talk-button {
    background-color: #4CAF50; /* Green */
    color: white;
  }
  
  .quote-button {
    background-color: #000305; /* Blue */
    color: white;
  }
  
  .talk-button:hover {
    background-color: #7eb881;
  }
  
  .quote-button:hover {
    background-color: #050708;
  }
  
.heroservice {
    display: flex; /* Use Flexbox to align items side by side */
    align-items: center; /* Vertically center the items */
    justify-content: space-between; /* Space the text and image apart */
    background: linear-gradient(
      to bottom,
      rgb(224, 242, 201) 0%, /* Light green sage accent */
      rgb(4, 7, 0) 50%, /* Keeps the green in the top part */
      rgb(4, 7, 0) 50%, /* Keeps the green in the top part */

      rgb(8, 3, 3) 100% /* Smooth transition to white */
    );    padding: 20px;
}

.heroservice-text {
    width: 50%; /* Set a width for the text container */
    text-align: left;
    padding: 110px;
    color: #ffffff;

}

.heroservice h1 {
    font-size: 2.5rem;
    color: rgb(150, 178, 61);
    margin: 0 0 10px;
}

.heroservice p {
    font-size: 1.2rem;
    margin: 10px 0; /* Add margin for spacing between heading and paragraph */
}

.heroservice-image {
    max-width: 45%; /* Limit the image width */
    height: auto;
    margin-top: 0;
    margin-bottom: -110px;

}

/* Services Section */
.servicesExtend {
    padding: 50px 20px;
    text-align: center;
}

.servicesExtend h2 {
    font-size: 2rem;
    margin-bottom: 30px;
    color: rgb(150, 178, 61);
}

.servicesExtend-cards {
    display: flex;
    flex-direction: column; /* Ensures the cards are arranged in one column */
    gap: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.servicesExtend-card {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    text-align: left;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.servicesExtend-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.servicesExtend-card h3 {
    font-size: 1.5rem;
    margin-bottom: 15px;
    color: rgb(150, 178, 61);
}

.servicesExtend-card p {
    font-size: 1rem;
    margin-bottom: 20px;
    color: #555;
}

.servicesExtend-card a {
    color: rgb(150, 178, 61);
    text-decoration: none;
    font-weight: bold;
    border-bottom: 1px solid transparent;
    transition: border-bottom 0.3s ease;
}

.servicesExtend-card a:hover {
    border-bottom: 1px solid rgb(150, 178, 61);
}

/* Select Section */
.services-section {
    padding: 50px 20px;
    text-align: center;
}

.services-section h2 {
    font-size: 2rem;
    margin-bottom: 30px;
    color: rgb(150, 178, 61);
}

.services-options,
.time-options {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
}

.services-option,
.time-option {
    background: #fff;
    border: 1px solid rgb(150, 178, 61);
    border-radius: 20px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background 0.3s ease, color 0.3s ease;
}

.services-option:hover,
.time-option:hover {
    background: rgb(150, 178, 61);
    color: #fff;
}

.services-option.active,
.time-option.active {
    background: rgb(150, 178, 61);
    color: #fff;
}

/* Details Section */
.details-section {
    padding: 50px 20px;
    background: #f4f4f4;
    text-align: center;
}

.details-section h2 {
    font-size: 2rem;
    margin-bottom: 30px;
    color: rgb(150, 178, 61);
}

.details-form {
    max-width: 600px;
    margin: 0 auto;
}

.details-form input,
.details-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
}

.details-form .submit-button {
    background: rgb(150, 178, 61);
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 1rem;
    transition: background 0.3s ease;
}

.details-form .submit-button:hover {
    background: #96b23d; /* Slightly darker shade for hover */
}

/* Responsive Design */
@media (max-width: 768px) {
    .header h1 {
        font-size: 2rem;
    }

    .services h2,
    .select-section h2,
    .details-section h2 {
        font-size: 1.8rem;
    }

    .service-card h3 {
        font-size: 1.3rem;
    }

    .details-form input,
    .details-form textarea {
        font-size: 0.9rem;
    }
}
/* General Responsive Styles */
@media (max-width: 768px) {
    /* Adjust hero section */
    .heroservice {
        flex-direction: column; /* Stack text and image vertically */
        text-align: center;
        padding: 20px;
    }

    .heroservice-text {
        width: 100%; /* Full width for text */
        padding: 20px; /* Reduce padding */
    }

    .heroservice-image {
        max-width: 80%; /* Reduce image size */
        margin: 0 auto;
    }

    .heroservice h1 {
        font-size: 2rem; /* Reduce font size */
    }

    .heroservice p {
        font-size: 1rem;
    }

    /* Adjust services section */
    .servicesExtend-cards {
        flex-direction: column; /* Stack cards vertically */
        gap: 15px;
    }

    .servicesExtend-card {
        padding: 15px; /* Reduce padding */
    }

    .servicesExtend-card h3 {
        font-size: 1.3rem;
    }

    .servicesExtend-card p {
        font-size: 0.9rem;
    }

    /* Adjust buttons */
    .service-card-buttons {
        flex-direction: column; /* Stack buttons vertically */
        align-items: center;
    }

    .talk-button, .quote-button {
        font-size: 0.9rem;
        padding: 8px 15px; /* Adjust button size */
    }
}

@media (max-width: 480px) {
    /* Hero section adjustments for very small screens */
    .heroservice h1 {
        font-size: 1.8rem;
    }

    .heroservice p {
        font-size: 0.9rem;
    }

    .heroservice-text {
        padding: 10px; /* Further reduce padding */
    }

    .heroservice-image {
        max-width: 100%; /* Full width */
    }

    /* Services cards */
    .servicesExtend-card h3 {
        font-size: 1.2rem;
    }

    .servicesExtend-card p {
        font-size: 0.8rem;
    }

    /* Form fields */
    .details-form input,
    .details-form textarea {
        font-size: 0.8rem;
        padding: 8px;
    }

    .details-form .submit-button {
        font-size: 0.9rem;
        padding: 8px 12px;
    }
}
